module.exports = {
  sanity: {
    projectId: "rbhjt84j",
    dataset: "production",
    apiVersion: "2023-01-12",
    token: process.env.SANITY_API_KEY,
    useCdn: true,
    ignoreBrowserTokenWarning: true,
  },
}
